import React from 'react'
import CardsEventos from '../CardsEventos/CardsEventos'

const Eventos = () => {
    return (
        <>
        <div className='exams padding-foot'>
            <div className='headExams d-flex justify-content-center align-items-center'>
                <h1 className='text-white text-center'>EVENTOS</h1>
                <img className='img-fluid' src="https://res.cloudinary.com/dsdicaf5h/image/upload/v1708426132/ici/eventos_ww6qde.jpg" alt="" />
            </div>
            <CardsEventos/>
        </div>
        </>
    )
}

export default Eventos