import React, { useContext, useEffect, useState } from 'react'
import { ConfigsContext } from '../Context/ConfigsContext';
import Button from 'react-bootstrap/Button';
import { generateNotifyError, generateNotifySuccess } from '../Context/Context';
import { AdminContext } from '../Context/AdminContext';
import Modal from 'react-bootstrap/Modal';
import { UserContext } from '../Context/UserContext'; 
const Configuracion = () => {
    const {getConfig, changeConfig} = useContext(ConfigsContext)
    const {downloadExcel, deleteAllStudents, newEntrie, downloadExcelEntries, deleteAllEntries, downloadAllExcelEntries } = useContext(AdminContext) 
    const { logUserToConfirm } = useContext(UserContext)
    const [registrationCost, setRegistrationCost] = useState(null);
    const [registrationStatus, setRegistrationStatus] = useState(null)
    const [rwlLevel, setRwlLevel] = useState(undefined);
    const [spkLevel, setSpkLevel] = useState({});
    const fetch = async () => {
        const resCost = await getConfig('registrationCost');
        const resInsc = await getConfig('registrationStatus');
        setRegistrationCost(resCost.data.value)
        setRegistrationStatus(resInsc.data.value)
    }; 
    useEffect(() => {
        fetch();
    }, []);

    const handleChangeConfig = async(key, newValue) =>{
    const res = await changeConfig(key, newValue)
    fetch()
    return res
    }


    const handleChangePrice = async() =>{
        const value = document.querySelector('#price').value
        if(value < 1) generateNotifyError('Ingresar un numero valido!')
        else{
        const res = await handleChangeConfig('registrationCost', value)
        if(res.status === 200) generateNotifySuccess('Precio actualizado!')
        }
    }

    const [showDelete, setShowDelete] = useState(false);
    const handleShowDeleteStudents = () =>{
        setShowDelete(true);
    }
    const handleCloseDelete = () => setShowDelete(false);

    const handleDeleteAllStudents = async (event) => {
        event.preventDefault();
        const email = document.querySelector('#user_email').value
        const password = document.querySelector('#user_password').value
        const userData = {
            email: email,
            password: password
        }
        const response = await logUserToConfirm(userData)
        if(response.message === 'Success') {
            await deleteAllStudents()
            handleCloseDelete()
        }
        
    }

    const [showDeleteEntrie, setShowDeleteEntrie] = useState(false);
    const handleShowDeleteEntrie = () =>{
        setShowDeleteEntrie(true);
    }
    const handleCloseDeleteEntrie = () => setShowDeleteEntrie(false);
    const handleDeleteEntries = async () =>{
        const res = await deleteAllEntries()
        if(res === 'success') handleCloseDeleteEntrie()
    };

    const handleSetRWL = (event, level) =>{
        event.preventDefault()
        setRwlLevel(level)
    }
    const handleSetSPK = (event, level)=>{
        event.preventDefault()
        setSpkLevel(level)
    }

    const handleSubmitNewEntrie = async (event)=>{
        event.preventDefault()
        const entrieData = {
        firstName: document.querySelector('#new_entrie_firstName').value,
        middleName: document.querySelector('#new_entrie_middleName').value,
        surName: document.querySelector('#new_entrie_surname').value,
        birth: document.querySelector('#new_entrie_birtday').value,
        RWL_Level: rwlLevel,
        SPK_Level: spkLevel,
        examType: document.querySelector('#new_entrie_exam').value
        }
        const res = await newEntrie(entrieData)
    };

    const handleDownloadExcelEntrie = async() =>{
        await downloadExcelEntries()
    }
    const handleDownloadAllExcel = async() =>{
        await downloadAllExcelEntries()
    }
    const handleDownloadExcel = async() =>{
	await downloadExcel()
    }
    return (
        <div className='inscriptos padding-foot padding-head'>
                <div className="card">
                    <div className="card-header">
                        <div className="text-header unYmedioEm">Exams Entries</div>
                    </div>
                    <div className='text-center th-header'>
                        <form onSubmit={handleSubmitNewEntrie} className='form-control d-flex flex-column align-items-center th-nouns unEm my-2'>
                            <div className="form-group div-search">
                                <label htmlFor="new_entrie_firstName" className='unEm'>FirstName</label>
                                <input required id='new_entrie_firstName' defaultValue='' className="form-control unEm bg-white" type="text"/>
                            </div>
                            <div className="form-group div-search">
                                <label htmlFor="new_entrie_middleName" className='unEm'>MiddleName</label>
                                <input id='new_entrie_middleName' defaultValue='' className="form-control unEm bg-white" type="text"/>
                            </div>
                            <div className="form-group div-search">
                                <label htmlFor="new_entrie_surname" className='unEm'>Surname</label>
                                <input required id='new_entrie_surname' defaultValue='' className="form-control unEm bg-white" type="text"/>
                            </div>
                            <div className="form-group div-search">
                                <label htmlFor="new_entrie_birtday" className='unEm'>Date Of Birtday</label>
                                <input required id='new_entrie_birtday' defaultValue='' className="form-control unEm bg-white" type="date"/>
                            </div>
                            <div className="form-group div-search">
                                <label htmlFor="new_entrie_RWLlevels" className='unEm'>RWL Levels</label>
                                <div className="dropdown">
                                    <button className="btn4 btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        see levels
                                    </button>
                                    
                                    <ul className="dropdown-menu overflow-scroll dropdown-entries" aria-labelledby="dropdownMenuButton1">
                                        <li><button className="dropdown-item" onClick={(event)=> handleSetRWL(event, 'FIRST STEP')}>FIRST STEP</button></li>
                                        <li><button className="dropdown-item" onClick={(event)=> handleSetRWL(event,'JUNIOR')}>JUNIOR</button></li>
                                        <li><button className="dropdown-item" onClick={(event)=> handleSetRWL(event,'PRIMARY')}>PRIMARY</button></li>
                                        <li><button className="dropdown-item" onClick={(event)=> handleSetRWL(event,'PRELIMINARY')}>PRELIMINARY</button></li>
                                        <li><button className="dropdown-item" onClick={(event)=> handleSetRWL(event,'ELEMENTARY')}>ELEMENTARY</button></li>
                                        <li><button className="dropdown-item" onClick={(event)=> handleSetRWL(event,'PRE-INTERMEDIATE')}>PRE-INTERMEDIATE</button></li>
                                        <li><button className="dropdown-item" onClick={(event)=> handleSetRWL(event,'INTERMEDIATE')}>INTERMEDIATE</button></li>
                                        <li><button className="dropdown-item" onClick={(event)=> handleSetRWL(event,'ADVANCED')}>ADVANCED</button></li>
                                        <li><button className="dropdown-item" onClick={(event)=> handleSetRWL(event,'PROFICIENCY')}>PROFICIENCY</button></li>
                                        <li><button className="dropdown-item" onClick={(event)=> handleSetRWL(event,'MASTERS')}>MASTERS</button></li>
                                        <li><button className="dropdown-item" onClick={(event)=> handleSetRWL(event,'IBE 1 PRACTICAL')}>IBE 1 PRACTICAL</button></li>
                                        <li><button className="dropdown-item" onClick={(event)=> handleSetRWL(event,'IBE 2 INTERMEDIATE')}>IBE 2 INTERMEDIATE</button></li>
                                        <li><button className="dropdown-item" onClick={(event)=> handleSetRWL(event,'IBE 3 ADVANCED')}>IBE 3 ADVANCED</button></li>
                                        <li><button className="dropdown-item" onClick={(event)=> handleSetRWL(event,'IBE 4 PROFICIENCY')}>IBE 4 PROFICIENCY</button></li>
                                        <li><button className="dropdown-item" onClick={(event)=> handleSetRWL(event,'CITE VERY YOUNG LEARNER')}>CITE VERY YOUNG LEARNER</button></li>
                                        <li><button className="dropdown-item" onClick={(event)=> handleSetRWL(event,'CITE YOUNG LEARNER')}>CITE YOUNG LEARNER</button></li>
                                        <li><button className="dropdown-item" onClick={(event)=> handleSetRWL(event,'CITE General')}>CITE General</button></li>
                                        <li><button className="dropdown-item" onClick={(event)=> handleSetRWL(event,'ORAL L1')}>ORAL L1</button></li>
                                        <li><button className="dropdown-item" onClick={(event)=> handleSetRWL(event,'ORAL L2')}>ORAL L2</button></li>
                                        <li><button className="dropdown-item" onClick={(event)=> handleSetRWL(event,'ORAL L3')}>ORAL L3</button></li>
                                        <li><button className="dropdown-item" onClick={(event)=> handleSetRWL(event,'ADULT STEP 1')}>ADULT STEP 1</button></li>
                                        <li><button className="dropdown-item" onClick={(event)=> handleSetRWL(event,'ADULT STEP 2')}>ADULT STEP 2</button></li>
                                        <li><button className="dropdown-item" onClick={(event)=> handleSetRWL(event,'ADULT STEP 3')}>ADULT STEP 3</button></li>
                                        <li><button className="dropdown-item" onClick={(event)=> handleSetRWL(event,'ADULT STEP 4')}>ADULT STEP 4</button></li>
                                        <li><button className="dropdown-item" onClick={(event)=> handleSetRWL(event,'NOT REGISTERED')}>NOT REGISTERED</button></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="form-group div-search">
                                <label htmlFor="new_entrie_SPKlevels" className='unEm'>SPK Levels</label>
                                <div className="dropdown">
                                    <button className="btn4 btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        see levels
                                    </button>
                                    
                                    <ul className="dropdown-menu overflow-scroll dropdown-entries" aria-labelledby="dropdownMenuButton1">
                                        <li><button className="dropdown-item" onClick={(event)=> handleSetSPK(event, 'FIRST STEP')}>FIRST STEP</button></li>
                                        <li><button className="dropdown-item" onClick={(event)=> handleSetSPK(event, 'JUNIOR')}>JUNIOR</button></li>
                                        <li><button className="dropdown-item" onClick={(event)=> handleSetSPK(event, 'PRIMARY')}>PRIMARY</button></li>
                                        <li><button className="dropdown-item" onClick={(event)=> handleSetSPK(event, 'PRELIMINARY')}>PRELIMINARY</button></li>
                                        <li><button className="dropdown-item" onClick={(event)=> handleSetSPK(event, 'ELEMENTARY')}>ELEMENTARY</button></li>
                                        <li><button className="dropdown-item" onClick={(event)=> handleSetSPK(event, 'PRE-INTERMEDIATE')}>PRE-INTERMEDIATE</button></li>
                                        <li><button className="dropdown-item" onClick={(event)=> handleSetSPK(event, 'INTERMEDIATE')}>INTERMEDIATE</button></li>
                                        <li><button className="dropdown-item" onClick={(event)=> handleSetSPK(event, 'ADVANCED')}>ADVANCED</button></li>
                                        <li><button className="dropdown-item" onClick={(event)=> handleSetSPK(event, 'PROFICIENCY')}>PROFICIENCY</button></li>
                                        <li><button className="dropdown-item" onClick={(event)=> handleSetSPK(event, 'MASTERS')}>MASTERS</button></li>
                                        <li><button className="dropdown-item" onClick={(event)=> handleSetSPK(event, 'IBE 1 PRACTICAL')}>IBE 1 PRACTICAL</button></li>
                                        <li><button className="dropdown-item" onClick={(event)=> handleSetSPK(event, 'IBE 2 INTERMEDIATE')}>IBE 2 INTERMEDIATE</button></li>
                                        <li><button className="dropdown-item" onClick={(event)=> handleSetSPK(event, 'IBE 3 ADVANCED')}>IBE 3 ADVANCED</button></li>
                                        <li><button className="dropdown-item" onClick={(event)=> handleSetSPK(event, 'IBE 4 PROFICIENCY')}>IBE 4 PROFICIENCY</button></li>
                                        <li><button className="dropdown-item" onClick={(event)=> handleSetSPK(event, 'CITE VERY YOUNG LEARNER')}>CITE VERY YOUNG LEARNER</button></li>
                                        <li><button className="dropdown-item" onClick={(event)=> handleSetSPK(event, 'CITE YOUNG LEARNER')}>CITE YOUNG LEARNER</button></li>
                                        <li><button className="dropdown-item" onClick={(event)=> handleSetSPK(event, 'CITE General')}>CITE General</button></li>
                                        <li><button className="dropdown-item" onClick={(event)=> handleSetSPK(event, 'CAT')}>CAT</button></li>
                                        <li><button className="dropdown-item" onClick={(event)=> handleSetSPK(event, 'DAT')}>DAT</button></li>
                                        <li><button className="dropdown-item" onClick={(event)=> handleSetSPK(event, 'ADULT STEP 1')}>ADULT STEP 1</button></li>
                                        <li><button className="dropdown-item" onClick={(event)=> handleSetSPK(event, 'ADULT STEP 2')}>ADULT STEP 2</button></li>
                                        <li><button className="dropdown-item" onClick={(event)=> handleSetSPK(event, 'ADULT STEP 3')}>ADULT STEP 3</button></li>
                                        <li><button className="dropdown-item" onClick={(event)=> handleSetSPK(event, 'ADULT STEP 4')}>ADULT STEP 4</button></li>
                                        <li><button className="dropdown-item" onClick={(event)=> handleSetSPK(event, 'NOT REGISTERED')}>NOT REGISTERED</button></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="form-group div-search">
                                <label htmlFor="new_entrie_exam" className='unEm'>Exam Type</label>
                                <input required id='new_entrie_exam' defaultValue='PAPER BASED' className="form-control unEm bg-white" type="text"/>
                            </div>
                            <input type='submit' value='Enter' className='btn3 unYmedioEm'/>
                        </form>
                        <div>
                            <button onClick={handleDownloadExcelEntrie} className='btn3 unYmedioEm'>Dowload Exams Entries PDF</button>
                        </div>
                        <div className='py-3'>
                            <button onClick={handleDownloadAllExcel} className='btn3 m-1 unYmedioEm'>Dowload All Exams Entries PDF</button>
                        </div>
                        <div className='py-3'>
                        <Button onClick={handleShowDeleteEntrie} className='unEm text-white bg-danger'>Delete All Exams Entries</Button>
                                <Modal className='modalLog text-center' show={showDeleteEntrie} onHide={handleCloseDeleteEntrie}>
                                    <Modal.Header className='mod-header' closeButton>
                                        <Modal.Title className='bg-white unEm rounded'>Eliminar</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body className='dosEm'>
                                        <p className='font-weight-bold text-danger'>Estas seguro que quieres eliminar todas las Exams Entries?</p>
                                    </Modal.Body>
                                    <Modal.Footer className='mod-footer'>
                                        <Button onClick={handleDeleteEntries} className='bg-danger border border-danger m-2 unYmedioEm'>Eliminar</Button>
                                        <Button className='btn4 unYmedioEm' onClick={handleCloseDeleteEntrie}>
                                            Cerrar
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                        </div>
                    </div>
                </div>
                
            <div className='card'>
                <div className="card-header">
                    <div className="text-header unEm">Configuracion</div>
                </div>
                <div className='text-center th-header flex-column align-items-center d-flex my-4'>
                    <h2 className='dosEm th-nouns'>Inscripciones</h2>
                    { registrationStatus === 'open' ? (
                            <>
                            <div className='m-2'>
                                <h4 className='bg-success mb-2 rounded p-2 text-white dosEm'>Abiertas</h4>
                                <Button onClick={()=>handleChangeConfig('registrationStatus', 'close')} className='bg-danger dosEm'>Cerrar Inscripciones</Button>
                            </div>
                            </>
                        ) : (
                            <>
                            <div className='m-2'>
                                <h4 className='bg-danger mb-2 rounded p-2 text-white dosEm'>Cerradas</h4>
                                <Button onClick={()=>handleChangeConfig('registrationStatus', 'open')} className='bg-success dosEm'>Abrir Inscripciones</Button>
                            </div>
                            </>
                        )
                    }
                    <h4 className='unYmedioEm mt-5 th-nouns'>Precio Actual= {registrationCost}$</h4>
                    <div className="form-group mt-2">
                        <input required className="form-control" name="price" id="price" type="number"/>
                        <Button className='mt-2 dosEm' onClick={handleChangePrice}>Cambiar Precio</Button>
                    </div>
                    <div>
                        <Button onClick={handleDownloadExcel} className='dosEm btn3'>Descargar Excel</Button>
                    </div>
                    <div className='my-5'>
                        <Button onClick={handleShowDeleteStudents} className='dosEm bg-danger'>Eliminar todos los Inscriptos</Button>
                    </div>
                    <Modal className='modalLog text-center' show={showDelete} onHide={handleCloseDelete}>
                        <Modal.Header className='mod-header' closeButton>
                            <Modal.Title className='bg-white unEm rounded'>Eliminar</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className='dosEm'>
                            <p className='font-weight-bold text-danger'>Estas seguro que quieres eliminar todos los inscriptos?</p>
                                <form className="row">
                                    <div className="form-group my-3">
                                        <label className='unEm' htmlFor="email">Email :</label>
                                        <input required className="form-control unEm" name="email" id="user_email" type="email"/>
                                    </div>
                                    <div className="form-group my-3">
                                        <label className='unEm' htmlFor="password">Contraseña :</label>
                                        <input required className="form-control unEm" name="password" id="user_password" type="password"/>
                                    </div>
                                </form>
                        </Modal.Body>
                        <Modal.Footer className='mod-footer'>
                            <Button onClick={handleDeleteAllStudents} className='bg-danger border border-danger m-2 unYmedioEm'>Eliminar</Button>
                            <Button className='btn4 unYmedioEm' onClick={handleCloseDelete}>
                                Cerrar
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
        </div>
    )
}

export default Configuracion
