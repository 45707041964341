import React from 'react';

const ExamsFotos = () => {
    return (
        <>
        <section className='section-fotos'>
            <div className="card-fotos ft1">
                <div className="card__img">  
                    <img className='img-fotos' src="https://res.cloudinary.com/dsdicaf5h/image/upload/v1706279196/ici/exams/exams4_vdeucq.png" alt="..." />
                </div>
            </div>
            <div className="card-fotos ft2">
                <div className="card__img">  
                    <img className='img-fotos' src="https://res.cloudinary.com/dsdicaf5h/image/upload/v1706279192/ici/exams/exams2_lrxns1.png" alt="..." />
                </div>
            </div>
            <div className="card-fotos ft3">
                <div className="card__img">  
                    <img className='img-fotos' src="https://res.cloudinary.com/dsdicaf5h/image/upload/v1708986136/ici/exams/exams1.2_bgu4rw.png" alt="..." />
                </div>
            </div>
            <div className="card-fotos ft4">
                <div className="card__img">  
                    <img className='img-fotos' src="https://res.cloudinary.com/dsdicaf5h/image/upload/v1706279193/ici/exams/exams7_jseo5l.png" alt="..."/>
                </div>
            </div>
            <div className="card-fotos ft5">
                <div className="card__img">  
                    <img className='img-fotos' src="https://res.cloudinary.com/dsdicaf5h/image/upload/v1706279195/ici/exams/exams5_s12n45.png" alt=".."/>
                </div>
            </div>
            <div className="card-fotos ft6">
                <div className="card__img">  
                    <img className='img-fotos' src="https://res.cloudinary.com/dsdicaf5h/image/upload/v1710513674/ici/exams/exams11_jojldk.png" alt="..."/>
                </div>
            </div>
        </section>
        <section className='section-fotos'>
            <div className="card-fotos ft1">
                <div className="card__img">  
                    <img className='img-fotos' src="https://res.cloudinary.com/dsdicaf5h/image/upload/v1706279193/ici/exams/exams3_r1spak.png" alt="..." />
                </div>
            </div>
            <div className="card-fotos ft2">
                <div className="card__img">  
                    <img className='img-fotos' src="https://res.cloudinary.com/dsdicaf5h/image/upload/v1706279196/ici/exams/exams9_adzcik.png" alt="..." />
                </div>
            </div>
            <div className="card-fotos ft3">
                <div className="card__img">  
                    <img className='img-fotos' src="https://res.cloudinary.com/dsdicaf5h/image/upload/v1710285043/ici/foto-ici-inst_f1ctsy.png" alt="..." />
                </div>
            </div>
        </section>
        </>
    );
};

export default ExamsFotos;