import React from 'react'
import CardsViajes from '../CardsViajes/CardsViajes.jsx'

const Viajes = () => {
    return (
        <div className='exams'>
            <div className='headExams d-flex justify-content-center align-items-center'>
                <h1 className='text-white text-center'>VIAJES<br />INTERNACIONALES</h1>
                <img className='img-fluid' src="https://res.cloudinary.com/dsdicaf5h/image/upload/v1688995983/ici/headerTravels_szknho.png" alt="" />
            </div>
            <CardsViajes/>
            <section className='video-viajes-div'>
                <iframe className='video-viajes' src="https://www.youtube.com/embed/vl4WiUrGjLM?si=cSKdXp-llZYqpBud" title="YouTube video player"></iframe>
            </section>
        </div>
    )
}

export default Viajes
