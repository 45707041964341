import React from 'react'

const EventosFotos = (img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, overlay) => {
    return (
        <>
        <section className='section-fotos'>
            <div className="card-fotos ft1">
                <div className="card__img">  
                    <img className='img-fotos' src={img1} alt="..." />
                    { overlay? (
                    <>
                        <div className="card__overlay">
                            <h2 className='dosEm'>{overlay}</h2>
                        </div>
                    </>
                ) : (<></>)}
                </div>
                
            </div>
            <div className="card-fotos ft2">
                <div className="card__img">  
                    <img className='img-fotos' src={img2} alt="..." />
                </div>
            </div>
            <div className="card-fotos ft3">
                <div className="card__img">  
                    <img className='img-fotos' src={img3} alt="..." />
                </div>
            </div>
            <div className="card-fotos ft4">
                <div className="card__img">  
                    <img className='img-fotos' src={img4} alt="..."/>
                </div> 
            </div>
            <div className="card-fotos ft5">
                <div className="card__img">  
                    <img className='img-fotos' src={img5} alt=".."/>
                </div>
            </div>
            <div className="card-fotos ft6">
                <div className="card__img">  
                    <img className='img-fotos' src={img6} alt="..."/>
                </div>
            </div>
        </section>
        {
            img7 ? (
                <>
                    <section className='section-fotos'>
                        <div className="card-fotos ft1">
                            <div className="card__img">  
                                <img className='img-fotos' src={img7} alt="..." />
                            </div>
                            
                        </div>
                        <div className="card-fotos ft2">
                            <div className="card__img">  
                                <img className='img-fotos' src={img8} alt="..." />
                            </div>
                        </div>
                        <div className="card-fotos ft3">
                            <div className="card__img">  
                                <img className='img-fotos' src={img9} alt="..." />
                            </div>
                        </div>
                        <div className="card-fotos ft4">
                            <div className="card__img">  
                                <img className='img-fotos' src={img10} alt="..."/>
                            </div> 
                        </div>
                        <div className="card-fotos ft5">
                            <div className="card__img">  
                                <img className='img-fotos' src={img11} alt=".."/>
                            </div>
                        </div>
                        <div className="card-fotos ft6">
                            <div className="card__img">  
                                <img className='img-fotos' src={img12} alt="..."/>
                            </div>
                        </div>
                    </section>
                </>
                ):(<></>)
            }
        </>
    )
}

export default EventosFotos