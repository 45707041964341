import React, { useState } from 'react'
import { Card, Button } from 'react-bootstrap';
import EventosFotos from './EventosFotos';

const CardsEventos = () => {
    const [expanded0, setExpanded0] = useState(false);
    const handleExpand0 = () => {
        setExpanded0(!expanded0);
    };

    const [expanded1, setExpanded1] = useState(false);
    const handleExpand1 = () => {
        setExpanded1(!expanded1);
    };

    const [expanded2, setExpanded2] = useState(false);
    const handleExpand2 = () => {
        setExpanded2(!expanded2);
    };

    const [expanded3, setExpanded3] = useState(false);
    const handleExpand3 = () => {
        setExpanded3(!expanded3);
    };

    const [expanded4, setExpanded4] = useState(false);
    const handleExpand4 = () => {
        setExpanded4(!expanded4);
    };

    const generateCardsEvent = (cardTitle, expand, handleExpand, img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, overlay) =>{
        return (
            <Card className= {expand ? 'expanded-card cardExams m-4' : 'collapsed-card cardExams m-4'}>
                <Card.Body>
                    <Card.Title><h3>{cardTitle}</h3></Card.Title>
                </Card.Body>
                {expand && (
                    <Card.Footer className="cardFooterLvls">
                        {EventosFotos(img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, overlay)}
                    </Card.Footer>
                )}
                    <Button className="btn2" variant="primary" onClick={handleExpand}>
                    {expand ? 'Ocultar imágenes' : 'Ver imágenes...'}
                </Button>
            </Card>
        )
    }

    return (
        <>
        <div className='cardsExams container-fluid'>
            {generateCardsEvent('Closing Party 2023', expanded0, handleExpand0, 
            'https://res.cloudinary.com/dsdicaf5h/image/upload/v1708428317/ici/Eventos/closingparty2_rvci5m.png',
            'https://res.cloudinary.com/dsdicaf5h/image/upload/v1708428377/ici/Eventos/closingparty10_aptpou.png',
            'https://res.cloudinary.com/dsdicaf5h/image/upload/v1708428422/ici/Eventos/closingparty14_q98iwk.png',
            'https://res.cloudinary.com/dsdicaf5h/image/upload/v1708428463/ici/Eventos/closingparty12_gvhjji.png',
            'https://res.cloudinary.com/dsdicaf5h/image/upload/v1710246741/ici/Eventos/Closing_party_2023_5_lhkyvx.jpg',
            'https://res.cloudinary.com/dsdicaf5h/image/upload/v1708428546/ici/Eventos/closingparty1_w2lfvk.png'
            )}
            {generateCardsEvent('Hallowen', expanded1, handleExpand1,
            'https://res.cloudinary.com/dsdicaf5h/image/upload/v1708438022/ici/Eventos/hallowen2_xiuv34.png',
            'https://res.cloudinary.com/dsdicaf5h/image/upload/v1708438015/ici/Eventos/hallowen1_hev0iv.png',
            'https://res.cloudinary.com/dsdicaf5h/image/upload/v1708438023/ici/Eventos/hallowen12_qtebnz.png',
            'https://res.cloudinary.com/dsdicaf5h/image/upload/v1708438020/ici/Eventos/hallowen10_kmnqxj.png',
            'https://res.cloudinary.com/dsdicaf5h/image/upload/v1708438021/ici/Eventos/hallowen6_jigfov.png',
            'https://res.cloudinary.com/dsdicaf5h/image/upload/v1708438017/ici/Eventos/hallowen3_ormc1i.png',
            'https://res.cloudinary.com/dsdicaf5h/image/upload/v1708438019/ici/Eventos/hallowen4_mntjx8.png',
            'https://res.cloudinary.com/dsdicaf5h/image/upload/v1708438017/ici/Eventos/hallowen8_zid0c4.png',
            'https://res.cloudinary.com/dsdicaf5h/image/upload/v1708438020/ici/Eventos/hallowen11_kvlptq.png',
            'https://res.cloudinary.com/dsdicaf5h/image/upload/v1708438024/ici/Eventos/hallowen9_hznv01.png',
            'https://res.cloudinary.com/dsdicaf5h/image/upload/v1708438022/ici/Eventos/hallowen7_rfdbqb.png',
            'https://res.cloudinary.com/dsdicaf5h/image/upload/v1708438015/ici/Eventos/hallowen5_i0ostf.png'
            )
            }
            {generateCardsEvent('Spring Picnic', expanded2, handleExpand2,
            'https://res.cloudinary.com/dsdicaf5h/image/upload/v1708455742/ici/Eventos/picnic5_hutohs.png',
            'https://res.cloudinary.com/dsdicaf5h/image/upload/v1708455744/ici/Eventos/picnic4_mxquf3.png',
            'https://res.cloudinary.com/dsdicaf5h/image/upload/v1708455740/ici/Eventos/picnic1_thuxia.png',
            'https://res.cloudinary.com/dsdicaf5h/image/upload/v1708455741/ici/Eventos/picnic2_cpolwt.png',
            'https://res.cloudinary.com/dsdicaf5h/image/upload/v1708455744/ici/Eventos/picnic3_iaxoqj.png',
            'https://res.cloudinary.com/dsdicaf5h/image/upload/v1708455742/ici/Eventos/picnic6_xzekzu.png'
            )
            }
            {generateCardsEvent('Entrega de Certificados', expanded3, handleExpand3,
            'https://res.cloudinary.com/dsdicaf5h/image/upload/v1708983672/ici/Eventos/entregacertificados12_ylrtt5.png',
            'https://res.cloudinary.com/dsdicaf5h/image/upload/v1708983666/ici/Eventos/entregacertificados5_si3ue1.png',
            'https://res.cloudinary.com/dsdicaf5h/image/upload/v1708983675/ici/Eventos/entregacertificados3_ulmvow.png',
            'https://res.cloudinary.com/dsdicaf5h/image/upload/v1708983674/ici/Eventos/entregacertificados1_engzve.png',
            'https://res.cloudinary.com/dsdicaf5h/image/upload/v1708983669/ici/Eventos/entregacertificados10_hwsipt.png',
            'https://res.cloudinary.com/dsdicaf5h/image/upload/v1708983671/ici/Eventos/entregacertificados9_u8vnml.png',
            'https://res.cloudinary.com/dsdicaf5h/image/upload/v1708983668/ici/Eventos/entregacertificados6_aoryuu.png',
            'https://res.cloudinary.com/dsdicaf5h/image/upload/v1708983673/ici/Eventos/entregacertificados7_vuhpw7.png',
            'https://res.cloudinary.com/dsdicaf5h/image/upload/v1708983673/ici/Eventos/entregacertificados2_kgaonn.png',
            'https://res.cloudinary.com/dsdicaf5h/image/upload/v1708983668/ici/Eventos/entregacertificados4_xdti6k.png',
            'https://res.cloudinary.com/dsdicaf5h/image/upload/v1708983667/ici/Eventos/entregacertificados8_etsu32.png',
            'https://res.cloudinary.com/dsdicaf5h/image/upload/v1708983671/ici/Eventos/entregacertificados11_vetaf3.png'
            )
            }
            {generateCardsEvent('Celebración de los 30 años de Anglia en la embajada británica', expanded4, handleExpand4,
            'https://res.cloudinary.com/dsdicaf5h/image/upload/v1710255065/ici/embajada-britanica-3_ysavzs.png',
            'https://res.cloudinary.com/dsdicaf5h/image/upload/v1710255064/ici/embajada-britanica-1_xzeg3t.png',
            'https://res.cloudinary.com/dsdicaf5h/image/upload/v1710255067/ici/embajada-britanica-5_ffch6n.png',
            'https://res.cloudinary.com/dsdicaf5h/image/upload/v1710255069/ici/embajada-britanica-6_gpcer4.png',
            'https://res.cloudinary.com/dsdicaf5h/image/upload/v1710255061/ici/embajada-britanica-2_ea1xee.png',
            'https://res.cloudinary.com/dsdicaf5h/image/upload/v1710255062/ici/embajada-britanica-4_wot3kh.png',
            null , null, null, null , null, null,
            'Presidente Anglia (Chris Kunz) y CEO Red Iberoamerica y el Caribe (Monica Blanco)'
            )
            }
        </div>
        </>
    )
}

export default CardsEventos
