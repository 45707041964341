import React, { useContext, useEffect, useState } from 'react'
import { ConfigsContext } from '../Context/ConfigsContext';
import Button from 'react-bootstrap/Button';
import { generateNotifyError, generateNotifySuccess } from '../Context/Context';
import { AdminContext } from '../Context/AdminContext';
import Modal from 'react-bootstrap/Modal';
import { UserContext } from '../Context/UserContext'; 
const Configuracion = () => {
    const {getConfig, changeConfig} = useContext(ConfigsContext)
    const {downloadExcel, deleteAllStudents} = useContext(AdminContext)
    const { logUserToConfirm } = useContext(UserContext)
    const [registrationCost, setRegistrationCost] = useState(null);
    const [registrationStatus, setRegistrationStatus] = useState(null)
    const fetch = async () => {
        const resCost = await getConfig('registrationCost');
        const resInsc = await getConfig('registrationStatus');
        setRegistrationCost(resCost.data.value)
        setRegistrationStatus(resInsc.data.value)
    }; 
    useEffect(() => {
        fetch();
    }, []);

    const handleChangeConfig = async(key, newValue) =>{
    const res = await changeConfig(key, newValue)
    fetch()
    return res
    }

    const handleDownloadExcel = async () =>{
        await downloadExcel()
    }

    const handleChangePrice = async() =>{
        const value = document.querySelector('#price').value
        if(value < 1) generateNotifyError('Ingresar un numero valido!')
        else{
        const res = await handleChangeConfig('registrationCost', value)
        if(res.status === 200) generateNotifySuccess('Precio actualizado!')
        }
    }

    const [showDelete, setShowDelete] = useState(false);
    const handleShowDeleteStudents = () =>{
        setShowDelete(true);
    }
    const handleCloseDelete = () => setShowDelete(false);

    const handleDeleteAllStudents = async (event) => {
        event.preventDefault();
        const email = document.querySelector('#user_email').value
        const password = document.querySelector('#user_password').value
        const userData = {
            email: email,
            password: password
        }
        const response = await logUserToConfirm(userData)
        if(response.message === 'Success') {
            await deleteAllStudents()
            handleCloseDelete()
        }
        
    }

    return (
        <div className='inscriptos padding-foot padding-head'>
            <div className='card'>
                <div className="card-header">
                    <div className="text-header unEm">Configuracion</div>
                </div>
                <div className='text-center th-header flex-column align-items-center d-flex my-4'>
                    <h2 className='dosEm th-nouns'>Inscripciones</h2>
                    { registrationStatus === 'open' ? (
                            <>
                            <div className='m-2'>
                                <h4 className='bg-success mb-2 rounded p-2 text-white dosEm'>Abiertas</h4>
                                <Button onClick={()=>handleChangeConfig('registrationStatus', 'close')} className='bg-danger dosEm'>Cerrar Inscripciones</Button>
                            </div>
                            </>
                        ) : (
                            <>
                            <div className='m-2'>
                                <h4 className='bg-danger mb-2 rounded p-2 text-white dosEm'>Cerradas</h4>
                                <Button onClick={()=>handleChangeConfig('registrationStatus', 'open')} className='bg-success dosEm'>Abrir Inscripciones</Button>
                            </div>
                            </>
                        )
                    }
                    <h4 className='unYmedioEm mt-5 th-nouns'>Precio Actual= {registrationCost}$</h4>
                    <div className="form-group mt-2">
                        <input required className="form-control" name="price" id="price" type="number"/>
                        <Button className='mt-2 dosEm' onClick={handleChangePrice}>Cambiar Precio</Button>
                    </div>
                    <div>
                        <Button onClick={handleDownloadExcel} className='dosEm btn3'>Descargar Excel</Button>
                    </div>
                    <div className='my-5'>
                        <Button onClick={handleShowDeleteStudents} className='dosEm bg-danger'>Eliminar todos los Inscriptos</Button>
                    </div>
                    <Modal className='modalLog text-center' show={showDelete} onHide={handleCloseDelete}>
                        <Modal.Header className='mod-header' closeButton>
                            <Modal.Title className='bg-white unEm rounded'>Eliminar</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className='dosEm'>
                            <p className='font-weight-bold text-danger'>Estas seguro que quieres eliminar todos los inscriptos?</p>
                                <form className="row">
                                    <div className="form-group my-3">
                                        <label className='unEm' htmlFor="email">Email :</label>
                                        <input required className="form-control unEm" name="email" id="user_email" type="email"/>
                                    </div>
                                    <div className="form-group my-3">
                                        <label className='unEm' htmlFor="password">Contraseña :</label>
                                        <input required className="form-control unEm" name="password" id="user_password" type="password"/>
                                    </div>
                                </form>
                        </Modal.Body>
                        <Modal.Footer className='mod-footer'>
                            <Button onClick={handleDeleteAllStudents} className='bg-danger border border-danger m-2 unYmedioEm'>Eliminar</Button>
                            <Button className='btn4 unYmedioEm' onClick={handleCloseDelete}>
                                Cerrar
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
        </div>
    )
}

export default Configuracion