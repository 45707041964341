import React, { createContext } from 'react';
import { fetchUrl, frontUrl } from '../config.js';
import { generateNotifyError, generateNotifySuccess } from './Context.jsx'
import Cookies from 'js-cookie';
export const ConfigsContext = createContext();


const ConfigsProvider = ({children}) =>{
    const token = Cookies.get('userToken');
	const getConfig = async (key) =>{
        try {
            const response = await fetch(`${fetchUrl}/api/config/${key}`, {
                method: 'GET',
                headers: {
                'Content-Type': 'application/json'
                },
            });
            if (response.ok) {
                const res = await response.json();
                return res
            } else {
                generateNotifyError('Hubo un error, prueba mas tarde!')
            }
        } catch (error) {
            generateNotifyError('Hubo un error, prueba mas tarde!')
            throw new Error(error)
        };
    };

    const changeConfig = async (key, newValue) =>{
        try {
            const response = await fetch(`${fetchUrl}/api/config`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
                body: JSON.stringify({key: key, newValue: newValue})
            });
            if (response.ok) {
                const res = await response.json();
                return res
            } else {
                const res = await response.json();
                if(res.errors === 'onlyTheOwnerIsAuthorized') return generateNotifyError('No estas autorizado!')
                if(res.errors === 'Unauthorized') {
                    setTimeout( ()=>{window.location.href = `${frontUrl}/inicio`}, 2000)
                    return generateNotifyError('Tu token expiro, vuelve a iniciar sesión!')
                }
                return generateNotifyError('Hubo un error, prueba mas tarde!')
            }
        } catch (error) {
            generateNotifyError('Hubo un error, prueba mas tarde!')
            throw new Error(error)
        };
    }
    return (
        <ConfigsContext.Provider value={{getConfig, changeConfig}}>
        {children}
        </ConfigsContext.Provider>
    )
};

export default ConfigsProvider
